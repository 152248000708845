















import { Component } from 'vue-property-decorator'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import AbstractField from '@/shared/classes/form/fields/abstract-field'
import { IFieldError } from '@/shared/interfaces/classes/form.interfaces'
import _ from 'lodash'
import compressFile from '@/shared/helpers/compress-file.helper'

@Component({
  components: {
    vueDropzone: vue2Dropzone
  }
})
export default class DropzoneField extends AbstractField {
  dropzoneOptions = {
    url: 'test',
    autoProcessQueue: false,
    autoQueue: false,
    addRemoveLinks: false,
    maxFilesize: 10000,
    clickable: true,
    acceptedFiles: this.field.meta.accept && this.field.meta.accept,
    previewTemplate: this.template(),
    dictDefaultMessage: this.field.title,
  }

  async fileUploaded(fileList: FileList) {
    let compressedFiles: File[] = []

    await Promise.all(Array.from(fileList).map(async (item: File) => {
      const compressedFile = await compressFile(item)
      compressedFiles.push(compressedFile)
    }))

    this.value = [
      ...this.value,
      ...compressedFiles
    ]
  }

  removedFile(file: File): void {
    this.value = this.value.filter((item: File) => item !== file)
  }

  getError(): IFieldError|boolean|null {
    if (! this.form) return null
    if (_.get(this.form.errors, this.fullKey)) {
      return _.get(this.form.errors, this.fullKey, {
        has: false,
        count: 0,
        messages: []
      })
    }

    return _.get(this.form.errors, this.fullKey + '_0', {
      has: false,
      count: 0,
      messages: []
    })
  }

  private template(): string {
    return `
      <div class="dz-preview dz-file-preview">
        <div class="dz-remove" data-dz-remove>
          <i class="v-icon mdi mdi-close"></i>
        </div>
        <div class="dz-image">
          <div data-dz-thumbnail-bg></div>
        </div>
        <div class="dz-details">
          <div class="dz-size"><span data-dz-size></span></div>
          <div class="dz-filename"><span data-dz-name></span></div>
        </div>
        <div class="dz-error-message"><span data-dz-errormessage></span></div>
        <div class="dz-success-mark"><i class="fa fa-check"></i></div>
        <div class="dz-error-mark"><i class="fa fa-close"></i></div>
      </div>
    `
  }

  private thumbnail(file: any, dataUrl: any): void {
    let j = null
    let len = null
    let ref = null
    let thumbnailElement = null

    if (file.previewElement) {
      file.previewElement.classList.remove('dz-file-preview')
      ref = file.previewElement.querySelectorAll('[data-dz-thumbnail-bg]')
      for (j = 0, len = ref.length; j < len; j++) {
        thumbnailElement = ref[j]
        thumbnailElement.alt = file.name
        thumbnailElement.style.backgroundImage = 'url("' + dataUrl + '")'
      }
      setTimeout(() => {
        file.previewElement.classList.add('dz-image-preview')
      }, 1)
    }
  }
}
